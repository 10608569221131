.feature {
  display: flex;
  gap: 20px;
}

.featureImage {
  width: 35px;
  height: 30px;
  margin-top: 5px;
}

.featureContent {
  width: 95%;
}

.featureContent h4 {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  margin-bottom: 0px;
}

.featureContent p {
  font-size: 0.8rem;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .featureImage {
    width: 40px;
    height: 40px;
  }

  .featureContent h4 {
    font-size: 1.25rem;
    margin-bottom: 5px;
  }

  .featureContent p {
    font-size: 0.9rem;
  }
}
