.feature {
  height: 100%;
  background: var(--neutral-light);
  border: 1px solid var(--blue-light-5);
  border-radius: 8px;
  padding: 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 35px;
}

.feature h3 {
  font-weight: 500;
  font-size: 1.05rem;
  margin: 0;
  margin-bottom: 10px;
}

.feature p {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.image {
  width: 100%;
  /* height: 300px; */
}

@media screen and (min-width: 768px) {
  .feature {
    padding: 25px;
  }

  .feature:hover {
    background-color: var(--orange-transparent-15);
  }

  .feature h3 {
    font-size: 1.25rem;
  }

  .feature p {
    font-size: 0.95rem;
  }
}
