.wrapper {
  font-family: "poppins";
  font-weight: lighter;
  padding: 50px 0;
}

.wrapper h3 {
  font-family: "PlayfairDisplay";
  color: var(--blue-dark);
  margin-bottom: 30px;
}

.accordionBody {
  font-family: "poppins";
  font-size: 0.85rem;
}

@media screen and (min-width: 768px) {
  .wrapper h3 {
    text-align: center;
  }
}
