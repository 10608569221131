.wrapper {
  background-color: var(--neutral-light);
}

.header p {
  margin-top: 20px;
  font-size: 0.85rem;
}

@media screen and (min-width: 768px) {
  .wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .contentWrapper {
    background: var(--blue-light-6);
    border: 1px solid var(--blue-light-5);
    border-radius: 10px;

    padding: 25px;
  }

  .header {
    text-align: center;
  }

  .header .label {
    text-transform: uppercase;
  }
}
