.wrapper {
  display: flex;
  gap: 50px;
}

.textWrapper {
  width: 60%;
  padding: 20px 0;
}

.imageWrapper {
  width: 40%;
  background-color: var(--orange-transparent-25);
  /* background-image: url("assets/images/landing/product-menu.png"); */

  display: flex;
  justify-content: center;
  align-items: center;
}

.imageAreaContent h3 {
  font-family: "PlayfairDisplay";
}

/**/
.navItem {
  display: flex;
  gap: 5px;
}

.itemDetails {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.itemDetails h3 {
  display: inline-block;
  font-size: 0.85rem;
  width: fit-content;
  border-radius: 5px;
  padding: 2px 10px 2px 5px;
}

.itemDetails p {
  font-size: 0.7rem;
  padding: 0 10px;
}

.navItem:hover .itemDetails h3 {
  color: var(--blue-primary);
  background-color: var(--blue-light-6);
}

.navItem:hover .icon {
  color: var(--blue-primary);
}

.imageAreaContent {
  /* height: 300px; */
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.linkBtn {
  color: var(--blue-primary);
  align-items: center;
  justify-content: flex-start !important;
  gap: 25px;
}

.linkBtn:hover {
  color: var(--blue-dark);
}

@media screen and (min-width: 1280px) {
  .textWrapper {
    padding: 30px 0;
  }
}

@media screen and (min-width: 1366px) {
  .textWrapper {
    padding: 50px 0;
  }
}

@media screen and (min-width: 1500px) {
  .itemDetails h3 {
    font-size: 1rem;
  }

  .itemDetails p {
    font-size: 0.85rem;
  }

  .wrapper {
    justify-content: space-between;
  }

  .imageWrapper {
    width: 35%;
  }
}
