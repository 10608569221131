.wrapper {
  padding-bottom: 50px;
  padding-top: 50px;
}

.header {
  margin-bottom: 30px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.imageWrapper {
  background: linear-gradient(0deg, #ffffff 5%, #ffaa2b55 90%);
  border-radius: 10px;
  padding: 10px;
}

.featureList {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media screen and (min-width: 768px) {
  .wrapper {
    padding-bottom: 50px;
    padding-top: 50px;
  }

  .header {
    text-align: center;
    margin-bottom: 50px;
  }

  .contentWrapper {
    width: 100%;
    flex-direction: row;
    gap: 50px;
  }

  .imageWrapper {
    width: 50%;
    height: fit-content;
    background: linear-gradient(90deg, #ffffff 5%, #ffaa2b55 90%);
  }

  .imageWrapper img {
    border-radius: 8px;
  }

  .featureList {
    width: 40%;
    gap: 30px;
  }
}

@media screen and (min-width: 1280px) {
  .imageWrapper {
    width: 60%;
  }
}
