.wrapper {
  position: fixed;
  left: 0;

  width: 100%;
  height: 100%;
  background-color: #00000052;
  border-top: 1px solid var(--blue-light-5);

  display: none;
}

.contentWrapper {
  width: 100%;
}

.content {
  min-height: 200px;
  padding: 0px 30px;
}

.label {
  display: flex;
  font-size: 0.75rem;
  gap: 3px;
  letter-spacing: 1px;
}

.label span {
  font-weight: 400;
  color: var(--neutral-dark-0);
}

@media screen and (min-width: 768px) {
  .wrapper {
    cursor: auto;
  }

  .contentWrapper {
    background-color: var(--neutral-light);
  }
}

@media screen and (min-width: 1280px) {
  .contentWrapper {
    background-color: var(--neutral-light);
  }
  .content {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0px 150px;
  }
}

@media screen and (min-width: 1366px) {
  .label {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 1536px) {
  .content {
    max-width: 1536px;
    margin: 0 auto;
    padding: 0px 150px;
  }
}
