.label {
  text-transform: uppercase;
  color: var(--blue-primary);
  font-weight: 500;
  font-size: 0.75rem;
}

.h2,
.h3 {
  font-family: "PlayfairDisplay";
  font-weight: 400;
}

.h2 {
  color: var(--blue-dark);
  font-size: 1.35rem;
  margin: 5px 0px 10px 0px;
}

.h3 {
  font-size: 1.15rem;
  margin: 0;
}

@media screen and (min-width: 768px) {

  .label {
    font-size: 0.95rem;
  }
  .h2 {
    font-size: 2.25rem;
  }
  
  .h3 {
    font-size: 2rem;
  }
}
