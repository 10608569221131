.wrapper {
  background-color: var(--blue-transparent-15);
}

.wrapper header {
  padding: 20px 20px 0 20px;

  display: flex;
  justify-content: space-between;
}

.wrapper img {
  width: 100%;
  height: 100%;
}

.logo {
  width: 28px;
  height: 28px;

  cursor: pointer;
}

.loginBtn {
  padding: 0;
  line-height: 28px;
  border: none;

  justify-content: flex-end;

  background-color: transparent;
  color: var(--blue-dark);

  cursor: pointer;
}

.loginBtn:hover {
  box-shadow: none;
}

/**/
.body {
  padding: 20px;
}

.formWrapper h1,
.infoWrapper h2,
.infoWrapper h2 span {
  font-family: "PlayfairDisplay";
  font-weight: 500;

  margin: 20px 0 0 0;
}

.infoWrapper {
  display: none;
}

@media screen and (min-width: 768px) {
  .formWrapper {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    padding: 0 15%;
  }

  .formWrapper h1 {
    width: 100%;
  }

  .formWrapper > p {
    margin-bottom: 50px;
  }

  .blue {
    color: var(--blue-dark);
  }

  .orange {
    color: var(--orange-primary);
  }
}

@media screen and (min-width: 1280px) {
  .wrapper {
    background-color: var(--neutral-light);
  }
  .wrapper header {
    background-color: var(--blue-transparent-15);
    padding: 20px 50px 20px 100px;

    width: 50%;
  }
  .body {
    display: flex;
    flex-direction: row-reverse;
    height: calc(100vh - 68px);

    padding: 0;
  }

  .formWrapper {
    justify-content: flex-start;
    align-items: flex-start;

    width: 50%;
    padding: 0px 100px 50px 100px;

    overflow-y: auto;
  }

  .infoWrapper {
    display: flex;
    flex-direction: column;

    background-color: var(--blue-transparent-15);
    background: linear-gradient(0deg, #ffaa2b25, #65b5ff15);
    padding: 0px 50px 50px 100px;
    width: 50%;

    overflow-y: auto;
  }

  .infoWrapper h2 {
    margin-bottom: 20px;
    line-height: 38px;
  }

  .infoWrapper h5 {
    margin-top: 50px;
    margin-bottom: 10px;
    font-weight: 500;
    color: var(--blue-dark);
  }

  .benefit {
    display: flex;
    font-size: 0.85rem;
    gap: 5px;

    margin-bottom: 5px;
  }

  .benefit .icon {
    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--green-primary);
  }

  .partners {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }

  .partnerLogo {
    display: inline-block;
    width: 100px;
  }
}

@media screen and (min-width: 1920px) {
  .wrapper header {
    padding: 20px 100px 20px 10%;
  }

  .formWrapper {
    padding: 100px 10% 50px 100px;
  }

  .infoWrapper {
    padding: 100px 100px 50px 10%;
  }

  .partnerLogo {
    display: inline-block;
    width: 150px;
  }
}
