.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: linear-gradient(196deg, #ffffff15 36%, #b9e6ff77 74%),
    linear-gradient(345deg, #ffe6a077 2%, #ffffff11 29%), linear-gradient(330deg, #b9f4ff44 22%, #ffffff 50%),
    linear-gradient(133deg, #ceecfe2f 9%, #ffffff15 55%), linear-gradient(24deg, #f2faffaa -5%, #ffffff22 10%),
    linear-gradient(205deg, #ffffff22 25%, #b7e4ff11 90%);

  /* background: linear-gradient(0deg, var(--blue-transparent), var(--neutral-light)); */
}

.layout {
  width: 100%;
  padding: 20px;
  padding-bottom: 50px;

  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  gap: 50px;
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.main h1 {
  display: block;
  margin: 0;
}
.mainAmpVoice {
  width: 300px;
  max-height: 490px;
}

.mainText {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  max-width: 600px;
  /* color: var(--blue-dark); */
}

.mainText h1 {
  font-family: "PlayfairDisplay";
  font-size: 1.45rem;
  font-weight: 500;
}

.tagline {
  font-family: "Poppins";
  font-size: 0.95rem;
  font-weight: 400;
}

.mainCTA {
  width: 100%;
  font-family: "Poppins";
  font-weight: 600;
  max-width: 220px;
  padding: 15px 0;
  text-align: center;
  border-radius: 5px;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
}

.landingImage {
  align-self: center;
  width: 60%;
  /* display: none; */
}

.landingImage img {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .layout {
    height: auto;

    padding-bottom: 100px;
    padding-top: 100px;
  }

  .mainWrapper {
    gap: 30px;
  }

  .mainText {
    text-align: center;
    max-width: unset;
    gap: 30px;
  }

  .main h1 {
    font-size: 36px;
  }

  .tagline {
    font-size: 20px;
    font-weight: 200;
  }

  .landingImage {
    /* display: inline-block; */
    align-self: center;
    width: 50%;
  }

  .ctaWrapper {
    align-self: center;
    width: fit-content;
  }
}

@media screen and (min-width: 1280px) {
  .layout {
    flex-direction: row;
    padding-left: 150px;
    padding-right: 150px;
    gap: 75px;
  }

  .mainWrapper {
    justify-content: center;
    width: 60%;
  }

  .mainText {
    text-align: left;
    gap: 30px;
  }

  .main h1 {
    font-size: 36px;
  }

  .tagline {
    font-size: 20px;
    font-weight: 200;
  }

  .landingImage {
    width: 40%;
  }

  .ctaWrapper {
    width: 100%;
  }
}

@media screen and (min-width: 1366px) {
  .layout {
    height: 75vh;
  }
}

@media screen and (min-width: 1550px) {
  
  .mainText h1 {
    font-size: 3rem;
  }
}
