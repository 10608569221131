img {
  width: 100%;
  height: 100%;
}

p,
div,
span {
  font-family: "poppins";
  font-weight: lighter;
}
