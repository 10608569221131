.wrapper {
  background-color: var(--blue-light-6);
  padding-top: 50px;
  padding-bottom: 50px;
}

.header {
  margin-bottom: 50px;
}

@media screen and (min-width: 768px) {
  .wrapper {
    padding-top: 50px;
    padding-bottom: 100px;
  }

  .header {
    text-align: center;
    margin-bottom: 75px;
    padding: 0 10%;
  }
}
