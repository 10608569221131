@keyframes bounce {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
}

.features {
  padding: 50px 0px;
}

.wrapper {
  display: flex;
  flex-direction: column;

  gap: 75px;
}

.wrapper h2 {
  font-family: "DMSerifDisplay";
  font-size: 28px;
  line-height: 40px;
  color: #333;
  text-align: center;
  padding-bottom: 25px;
}

.blueText {
  color: var(--blue-primary);
}
.wrapper h2 .stud {
  color: var(--orange-primary);
  letter-spacing: 2px;
}

.featureList {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 100px;
}

@media screen and (min-width: 768px) {
  .otherFeatures {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1024px) {
  .features {
    padding: 100px 0;
  }

  .wrapper {
    gap: 150px;
  }

  .wrapper h2 {
    font-size: 45px;
    line-height: 40px;
  }

  .featureList {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 120px;
    padding-top: 50px;
  }
}

@media screen and (min-width: 1440px) {
  .featureList {
    column-gap: 0px;
  }
}
