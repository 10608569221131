.small {
  font-size: 0.8rem;
}

.medium {
  font-size: 0.85rem;
}

@media screen and (min-width: 768px) {
  .small {
    font-size: 0.85rem;
  }

  .medium {
    font-size: 0.95rem;
  }
}
