.header {
  background-color: var(--neutral-light);
  border-bottom: 1px solid var(--blue-transparent);
  color: var(--neutral-dark-2);
  font-weight: 400;
  padding: 15px 0px;
  width: 100%;

  position: sticky;
  top: 0;
  left: 0;

  z-index: 5;
}

.navWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 10;
}

.siteBranding {
  display: flex;
  justify-content: center;
  align-items: center;
}

.siteLink {
  text-decoration: none;
  display: flex;
}

.siteInfo {
  display: flex;
  justify-content: space-between;
}

.siteInfo nav {
  margin-left: 60px;
}

.siteIcon {
  width: 24px;
  max-height: 24px;
}

.siteName {
  color: var(--blue-dark);
  font-size: 22px;
  padding-left: 5px;
  font-weight: 500 !important;
  font-family: "PlayfairDisplay";

  display: flex;
}

.siteName a {
  color: var(--blue-dark);
}

.siteName > .stub {
  color: var(--orange-primary);
  letter-spacing: 1px;
}

.navBar a,
.mobileNav a {
  display: block;
  text-decoration: none;
  align-items: center;
  color: var(--neutral-dark-0);
  font-weight: 400;
  letter-spacing: 1px;
}

.navBar a:hover,
.mobileNav a:hover {
  font-weight: 500;
}

.mobileNav a {
  color: var(--neutral-dark-0);
}

.navBar,
.headBtn {
  display: none;
}

.headBtn {
  gap: 15px;
  margin-right: 15px;
  justify-content: space-evenly;
}

.siteInfo {
  cursor: pointer;
}

.options {
  display: flex;
  justify-content: center;
  align-items: center;
}

.options svg {
  width: 30px;
  height: 30px;
}

.mobileNav {
  position: fixed;
  top: 65px;
  left: -100%;
  width: 100%;
  height: calc(100vh - 65px);
  transition: all 0.3s ease;
  z-index: 10;

  background-color: #00000052;
}

.mobileNavWrapper {
  height: 100%;
  width: 85%;

  padding: 10px 20px 50px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 50px;

  background: var(--neutral-light);
}

.mobileNavWrapper a {
  padding: 7.5px 0;
}

.accordionItem {
  border: none !important;
}

.accordionHeader button {
  background: transparent !important;
  box-shadow: none !important;
  padding: 7.5px 0;
}

.accordionBody {
  border-left: 1px solid var(--neutral-dark-5);
  padding: 10px;
}

.subItems {
  display: flex;
  flex-direction: column;
  font-size: 0.85rem;
}

.subItems span {
  font-weight: 400;
}

.showNavbar {
  left: 0;
}

@media screen and (min-width: 768px) {
  .siteInfo nav {
    margin-left: 20px;
  }

  .navBar {
    display: flex;
    align-items: center;
  }

  .navLinks {
    display: flex;
    gap: 25px;
    font-size: 0.75rem;
  }

  .navBar a {
    display: inline;
  }

  .headBtn {
    display: flex;
    flex-direction: row;
    margin: 0;
  }

  .options {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .navBar a {
    font-size: 18px;
    font-size: 0.75rem;
  }

  .siteName {
    padding-left: 10px;
  }

  .mobileNav {
    display: none;
  }
}

@media screen and (min-width: 1366px) {
  .siteIcon {
    margin-right: 8px;
  }

  .siteInfo nav {
    margin-left: 50px;
  }

  .siteName {
    font-size: 20px;
    line-height: 28px;
    margin-top: 2px;
    padding-left: 0;
  }

  .navBar a {
    font-size: 0.9rem;
  }
}
