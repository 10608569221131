.review {
  padding: 100px 0;
  background-color: var(--blue-dark);
}

.revewContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 50px;

  width: 100%;
}

.revewContainer h2 {
  font-family: "PlayfairDisplay";
  font-size: 24px;
  font-weight: 400;
  margin: 0px;
  color: var(--neutral-light);
  text-align: center;
}

.blueText {
  color: var(--blue-primary);
}

.revewContainer h2 .stud {
  color: var(--orange-primary);
  letter-spacing: 2px;
}

.revewList {
  display: flex;
  gap: 20px;
}

.revewList::-webkit-scrollbar {
  display: none;
}

.revewList::-webkit-scrollbar-thumb {
  background-color: #d6d6d6;
  border-radius: 10px;
}

.openQuote {
  width: 50px;
}

.openQuote img {
  width: 100%;
}

.testimonyCard {
  border-radius: 8px;

  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.testimonyCard p {
  color: var(--neutral-dark-6);
  font-family: "Poppins";
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 1px;
  margin: 0;
  white-space: pre-line;
}

.testimonyAuthor {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.testimonyImage {
  width: 50px;
  height: 50px;
  border: 1px solid var(--blue-primary);
  border-radius: 50%;
  margin-right: 15px;
  padding: 2.5px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.authorIcon {
  width: 100%;
  /* height: 100%; */
  border-radius: 50%;
}

.authorInfo h4 {
  font-size: 1.25rem;
  color: var(--neutral-light);
  font-weight: 500;
  margin: 0;
}

.authorInfo h5 {
  font-size: 1.05rem;
  font-weight: 200;
  color: var(--neutral-dark-5);
  margin: 0;
}

@media screen and (min-width: 768px) {
  .revewContainer {
    gap: 20px;
  }

  .revewContainer h2 {
    font-size: 36px;
    text-align: left;
    margin-bottom: 50px;
    width: 60%;
  }

  .revewList {
    display: flex;
    gap: 20px;

    width: 100%;
    overflow-x: auto;
  }

  .testimonyCard {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .revewContainer {
    gap: 30px;
  }

  .revewContainer h2 {
    margin-bottom: 0px;
    width: 60%;
  }
}

@media screen and (min-width: 1280px) {
  .revewContainer h2 {
    font-size: 32px;
    margin-bottom: 0px;
  }
}
