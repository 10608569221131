.wrapper {
  width: 100%;
  background-color: var(--neutral-light);

  border: 1px solid var(--blue-transparent-75);
  border-radius: 10px;
  padding: 20px;
}

.btn {
  font-weight: 600;
  line-height: 40px;
  cursor: pointer;
}

@media screen and (min-width: 1280px) {
  .wrapper {
    border: none;
    padding: 0;
  }
}
