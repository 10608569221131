.wrapper {
  background-color: var(--neutral-light);
  font-size: 0.75rem;

  padding: 10px 15px;
  margin-top: 25px;

  border: 1px solid var(--blue-light-5);
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.wrapper img {
  width: 100%;
  height: 100%;
}

.wrapper p {
  margin: 0;
}

.author {
  display: flex;
  gap: 10px;
}

.authorIcon {
  width: 30px;
}

.authorName {
  font-weight: 600;
}

.authorCompany {
  color: var(--neutral-dark-3);
}
