.formGroup,
.iconFormGroup {
  width: 100%;
  margin-bottom: 10px;
  padding: 2px;

  background-color: var(--neutral-light);
}

input,
textarea {
  line-height: 36px !important;
  width: 100%;

  border: 1px solid var(--neutral-dark-4);
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 5px;
  outline: none;
  color: var(--neutral-dark-2);
}

textarea {
  line-height: unset !important;
  padding: 5px 10px;
}

.counter {
  display: inline-block;

  font-size: 14px;
  text-align: right;
}

.required {
  color: var(--danger);
}

.date {
  width: 100%;
}

input:focus,
input:hover,
textarea:focus,
textarea:hover {
  border-color: var(--blue-primary);
}

input:invalid,
textarea:invalid {
  border-color: var(--danger);
}

input:disabled textarea:disabled {
  border-color: var(--neutral-dark-5);
}

.iconFormGroup {
  display: flex;
  justify-content: center;
  align-content: center;

  border: 1px solid var(--neutral-dark-4);
  border-radius: 5px;
}

.iconFormGroup input {
  border: none;
  background-color: transparent;
}

.inputIcon {
  align-self: center;
  height: fit-content;
  margin: 0 5px;
  color: var(--neutral-dark-3);
}

.inputIconLeft {
  padding-left: 0;
}

.iconFormGroup:focus-within,
.iconFormGroup:hover {
  border-color: var(--blue-primary);
}

.rounded {
  border-radius: 50px;
  padding: 2px;
}

/**/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: "color 9999s ease-out, background-color 9999s ease-out";
  transition-delay: 9999s;

  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: lighter;
  line-height: 24px;
}

.checkboxWrapper input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;
}

.checkboxWrapper:hover input ~ .gmCheckbox {
  background-color: var(--neutral-dark-6);
}

.checkboxWrapper input:checked ~ .gmCheckbox {
  background-color: var(--blue-primary);
  border-color: var(--blue-primary);
}

.checkboxWrapper input:checked ~ .gmCheckbox::after {
  color: var(--neutral-light);
  display: block;
  margin-top: -4px;
  margin-left: 2px;
}

.gmCheckbox {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid var(--neutral-dark-4);
  border-radius: 2px;
  cursor: pointer;
}

.gmCheckbox::after {
  content: "✔";
  position: absolute;
  display: none;
}

.checkboxOn {
  background-color: var(--blue-primary);
}

.checkboxWrapper div {
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

.error {
  color: var(--danger);
  font-size: 12px;
}

.errorBorder {
  border-color: var(--danger) !important;
}

@media screen and (min-width: 768px) {
  .iconFormGroup {
    height: 40px;
  }
}
