.wrapper {
  padding-bottom: 50px;
  padding-top: 50px;
}

.header {
  margin-bottom: 30px;
}

.contentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.imageWrapper {
  border-radius: 10px;
  background: linear-gradient(0deg, #ffffff 5%, #2b99ff55 90%);

  padding: 10px;
}

.featureList {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media screen and (min-width: 768px) {
  .wrapper {
    padding-bottom: 100px;
    padding-top: 50px;
  }

  .header {
    text-align: center;
    margin-bottom: 50px;
  }

  .contentWrapper {
    flex-direction: row;
    gap: 50px;
  }

  .imageWrapper {
    width: 50%;
    height: fit-content;
    border-radius: 10px;
    background: linear-gradient(90deg, #ffffff 5%, #2b99ff55 90%);

    padding: 10px;
  }

  .imageWrapper img {
    border-radius: 8px;
  }

  .featureList {
    width: 40%;
    gap: 30px;
  }
}

@media screen and (min-width: 1280px) {
  .imageWrapper {
    width: 60%;
  }
}
