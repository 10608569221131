.wrapper {
  background: linear-gradient(0deg, #ffffff 40%, #ffaa2b15 90%);
  border-radius: 10px;
  margin: 0;
  padding: 75px 0;
}

.pricingWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.pricingInfo {
  flex-direction: column;
  align-items: self-start;
}

.heading {
  font-family: "PlayfairDisplay", serif;
  font-weight: 500;
  font-size: 24px;
  color: var(--neutral-dark-1);
}

.subheading {
  color: var(--neutral-dark-1);
  font-size: 14px;
  margin: 20px 0 50px 0;
}

.getStartedBtn {
  border: none;
  border-radius: 50px;
  padding: 0px 35px;
  font-family: "poppins";
  font-size: 1.1rem;
  margin-top: 20px;
  cursor: pointer;
}

.plansSection {
  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 100%;
}

.currencyWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0px;
  width: 100%;
}

.moduleSelector {
  width: 100%;
}

.currency {
  width: 100%;
}

.plansWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.planWrapper {
  background-color: var(--neutral-light);
  background: linear-gradient(180deg, #ffffff 40%, #ffaa2b15 90%);

  border: 1px solid var(--neutral-dark-5);
  border-radius: 8px;
  padding: 20px;
  width: 100%;

  font-weight: unset;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.subscription {
}

.planTitle {
  color: var(--blue-dark);
  font-family: "Poppins";
  font-weight: 500;

  margin: 0;
  margin-bottom: 5px;
}

.planPrice {
  color: var(--neutral-dark-2);
  font-size: 12px;
  font-weight: lighter;

  margin-bottom: 10px;
}

.planPrice > span {
  font-size: 28px;
  font-weight: bold;
  color: var(--blue-dark);
}

span.oldPrice {
  text-decoration: line-through;
  font-size: 1.35rem;
  font-weight: 500;
  color: var(--neutral-dark-3);
}

.switchWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.switchWrapper > span {
  color: var(--neutral-dark-2);
  font-size: 12px;
}

.benefits {
  display: flex;
  flex-direction: column;
  gap: 2px;

  border-top: 1px solid var(--blue-transparent-75);
  padding-top: 20px;
}

.subscription .benefits {
  border-top: 1px solid var(--blue-transparent-75);
}

.benefitTitle {
  font-size: 0.75rem;
  font-weight: 500;
}

.benefit {
  display: flex;
  align-items: center;
  gap: 10px;

  color: var(--neutral-dark-2);
  font-size: 12px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--blue-dark);
}

.btn,
.customBtn {
  border: none;

  color: var(--blue-dark);
  background-color: var(--neutral-light);
  border: 1px solid var(--blue-dark);
  border-radius: 5px;
  width: 100%;

  cursor: pointer;
}

.customBtn {
  color: var(--neutral-light);
  background-color: var(--blue-primary);
  border-color: var(--blue-primary);
}

@media screen and (min-width: 768px) {
  .heading {
    font-size: 32px;
  }

  .subheading {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .pricingWrapper {
    align-items: unset;
  }

  .pricingInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .plansWrapper {
    flex-direction: row;
  }

  .planTitle {
    text-align: left;
    font-size: 1.2rem;
  }

  .currencyWrapper {
    flex-direction: row;
    gap: 15px;
  }

  .moduleSelector {
    width: 28.25%;
  }

  .currency {
    width: 150px;
  }
}

@media screen and (min-width: 1024px) {
  .pricingWrapper {
    flex-direction: column;
    justify-content: space-between;
  }

  .pricingInfo {
    display: flex;
    width: 100%;
  }

  .heading {
    margin-bottom: 0;
  }
}
