.button {
  display: flex;
  align-items: center;
  gap: 10px;

  font-family: "Poppins";
  line-height: 40px;
  min-width: 100px;
  padding: 0 25px;

  border: 1px solid var(--blue-primary);
  border-radius: 50px;

  background-color: var(--neutral-light);
  color: var(--blue-primary);
  font-weight: 500;
  font-size: 15px;
}

.button:hover {
  box-shadow: 0px 0px 3.5px var(--neutral-dark-3);
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cta {
  background-color: var(--blue-primary);
  border-color: var(--blue-primary);
  color: var(--neutral-light);
  font-family: "Poppins";
  font-weight: 400;
  font-size: 32px;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.center {
  justify-content: center;
}

.right {
  justify-content: flex-end;
}

.loginBtn,
.signUpBtn {
  line-height: 40px;
  border-radius: 5px;
  min-width: unset;
  padding: 0 20px;
  font-size: 14px;
  cursor: pointer;
}

.loginBtn {
  background: var(--blue-primary) !important;
  color: var(--neutral-light) !important;
}

.signUpBtn {
  color: var(--blue-dark);
  border-color: var(--blue-dark);
}

@media screen and (min-width: 768px) {
  .cta {
    padding: 10px 25px;
  }

  .actions {
    display: flex;
    flex-direction: row;
  }
}
